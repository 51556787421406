import dontTrack from '@rategravity/frontend/modules/dont-track';
import { googleMapsApiKey } from './src/modules/api-keys';
import React from 'react';
import ReactDOM from 'react-dom';

if (navigator.userAgent.indexOf('MSIE') >= 0) {
  window.location.href = '/unsupported.html';
} else {
  if (!dontTrack && process.env.NODE_ENV === 'production') {
    require('@rategravity/frontend/modules/segment-init');
  }
}

if (window.document && window.document.createElement) {
  const script = window.document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
  script.type = 'text/javascript';
  window.document.head.appendChild(script);
}

// Displays console messages for a11y violations.
// Only for use in development.
if (process.env.NODE_ENV !== 'production') {
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}
